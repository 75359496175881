<template>
  <div>
    <van-popup
      v-model="_ticketBoolean"
      closeable
      position="bottom"
      round
      :style="{ height: '80%' }">
      <div class="container">
        <p class="container-title">选择优惠券</p>
        <div class="tickerListBox">
          <div>
            <p v-if="sysCoupons.length">平台优惠券</p>
            <div class="tickerList" v-for="x in sysCoupons" :key="x.id" @click="selectTicket(0, x.id, x.name, x.premiseAmount)">
              <div class="tickerList-left">
                <div>
                  <span style="font-size: 14px; color: #F52555;">{{ x.type == '0' ? x.discount : '￥' }}</span>
                  <span style="font-size: 20px; color: #F52555;">{{ x.type == '0' ? '折' : x.reduceAmount }}</span>
                </div>
                <p style="font-size: 12px;">{{ x.name }}</p>

                <div class="tickerList-left-topYuan tickerList-left-yuan"></div>
                <div class="tickerList-left-botYuan tickerList-left-yuan"></div>
              </div>
              <div class="tickerList-right">
                <div class="tickerList-right-shuo">
                  <p>{{ x.name }}</p>
                  <p>{{ x.suitType == '2' ? '全平台' : '店铺' }}商品使用</p>
                </div>
                <div class="tickerList-right-img">
                  <img v-if="ids[0] == x.id" src="../img/active.png" alt="">
                  <img v-else src="../img/isactive.png" alt="">
                </div>
              </div>
            </div>
          </div>

          <div>
            <p v-if="shopCoupons.length">店铺优惠券</p>
            <div class="tickerList" v-for="x in shopCoupons" :key="x.id" @click="selectTicket(1, x.id, x.name, x.premiseAmount, x.isShare)">
              <div class="tickerList-left">
                <div>
                  <span style="font-size: 14px; color: #F52555;">{{ x.type == '0' ? x.discount : '￥' }}</span>
                  <span style="font-size: 20px; color: #F52555;">{{ x.type == '0' ? '折' : x.reduceAmount }}</span>
                </div>
                <p style="font-size: 12px;">{{ x.name }}</p>

                <div class="tickerList-left-topYuan tickerList-left-yuan"></div>
                <div class="tickerList-left-botYuan tickerList-left-yuan"></div>
              </div>
              <div class="tickerList-right">
                <div class="tickerList-right-shuo">
                  <p>{{ x.name }}</p>
                  <p>{{ x.suitType == '2' ? '全平台' : '店铺' }}商品使用</p>
                </div>
                <div class="tickerList-right-img">
                  <img v-if="ids[1] == x.id" src="../img/active.png" alt="">
                  <img v-else src="../img/isactive.png" alt="">
                </div>
              </div>
            </div>
          </div>

          <div>
            <p v-if="vipCoupons.length">VIP优惠券</p>
            <div class="tickerList" v-for="x in vipCoupons" :key="x.id" @click="selectTicket(2, x.id, x.name, x.premiseAmount)">
              <div class="tickerList-left">
                <div>
                  <span style="font-size: 14px; color: #F52555;">{{ x.type == '0' ? x.discount : '￥' }}</span>
                  <span style="font-size: 20px; color: #F52555;">{{ x.type == '0' ? '折' : x.reduceAmount }}</span>
                </div>
                <p style="font-size: 12px;">{{ x.type == '0' ? '折扣券' : '满减券' }}</p>

                <div class="tickerList-left-topYuan tickerList-left-yuan"></div>
                <div class="tickerList-left-botYuan tickerList-left-yuan"></div>
              </div>
              <div class="tickerList-right">
                <div class="tickerList-right-shuo">
                  <p>{{ x.name }}</p>
                  <p>{{ x.suitType == '2' ? '全平台' : '店铺' }}商品使用</p>
                </div>
                <div class="tickerList-right-img">
                  <img v-if="ids[2] == x.id" src="../img/active.png" alt="">
                  <img v-else src="../img/isactive.png" alt="">
                </div>
              </div>
            </div>
          </div>

          <div>
            <p v-if="stageCoupons.length">驿站券</p>
            <div class="tickerList" v-for="x in stageCoupons" :key="x.id" @click="selectTicket(3, x.id, x.name, x.premiseAmount)">
              <div class="tickerList-left">
                <div>
                  <span style="font-size: 14px; color: #F52555;">{{ x.type == '0' ? x.discount : '￥' }}</span>
                  <span style="font-size: 20px; color: #F52555;">{{ x.type == '0' ? '折' : x.reduceAmount }}</span>
                </div>
                <p style="font-size: 12px;">无门槛</p>

                <div class="tickerList-left-topYuan tickerList-left-yuan"></div>
                <div class="tickerList-left-botYuan tickerList-left-yuan"></div>
              </div>
              <div class="tickerList-right">
                <div class="tickerList-right-shuo">
                  <p>{{ x.name }}</p>
                  <p>{{ x.suitType == '2' ? '全平台' : '店铺' }}商品使用</p>
                </div>
                <div class="tickerList-right-img">
                  <img v-if="ids[3] == x.id" src="../img/active.png" alt="">
                  <img v-else src="../img/isactive.png" alt="">
                </div>
              </div>
            </div>
          </div>

          <div>
            <p v-if="synthesisCoupon.length">活动券</p>
            <div class="tickerList" v-for="x in synthesisCoupon" :key="x.id" @click="selectTicket(4, x.id, x.name, x.premiseAmount)">
              <div class="tickerList-left">
                <div>
                  <span style="font-size: 14px; color: #F52555;">{{ x.type == '0' ? x.discount : '￥' }}</span>
                  <span style="font-size: 20px; color: #F52555;">{{ x.type == '0' ? '折' : x.reduceAmount }}</span>
                </div>
                <p style="font-size: 12px;">{{ x.type == '0' ? '折扣券' : '满减券' }}</p>

                <div class="tickerList-left-topYuan tickerList-left-yuan"></div>
                <div class="tickerList-left-botYuan tickerList-left-yuan"></div>
              </div>
              <div class="tickerList-right">
                <div class="tickerList-right-shuo">
                  <p>{{ x.name }}</p>
                  <p>{{ x.suitType == '2' ? '全平台' : '店铺' }}商品使用</p>
                </div>
                <div class="tickerList-right-img">
                  <img v-if="ids[4] == x.id" src="../img/active.png" alt="">
                  <img v-else src="../img/isactive.png" alt="">
                </div>
              </div>
            </div>
          </div>

          <div>
            <p v-if="taskCoupons.length">任务券</p>
            <div class="tickerList" v-for="x in taskCoupons" :key="x.id" @click="selectTicket(5, x.id, x.name, x.premiseAmount)">
              <div class="tickerList-left">
                <div>
                  <span style="font-size: 14px; color: #F52555;">{{ x.type == '0' ? x.discount : '￥' }}</span>
                  <span style="font-size: 20px; color: #F52555;">{{ x.type == '0' ? '折' : x.reduceAmount }}</span>
                </div>
                <p style="font-size: 12px;">{{ x.type == '0' ? '折扣券' : '满减券' }}</p>

                <div class="tickerList-left-topYuan tickerList-left-yuan"></div>
                <div class="tickerList-left-botYuan tickerList-left-yuan"></div>
              </div>
              <div class="tickerList-right">
                <div class="tickerList-right-shuo">
                  <p>{{ x.name }}</p>
                  <p>{{ x.suitType == '2' ? '全平台' : '店铺' }}商品使用</p>
                </div>
                <div class="tickerList-right-img">
                  <img v-if="ids[5] == x.id" src="../img/active.png" alt="">
                  <img v-else src="../img/isactive.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn" v-if="sysCoupons.length || shopCoupons.length || vipCoupons.length || stageCoupons.length || synthesisCoupon.length || taskCoupons.length">
          <div class="btn-radio">
            <span>
            </span>
            <span>
              <van-checkbox
                icon-size="18px" 
                v-model="isTicket"
                @change="change"
                checked-color="#F52555">不使用优惠券</van-checkbox>
            </span>
          </div>
          <div>
            <van-button round color="#F52555" size="large" @click="comfirm">确认</van-button>
          </div>
        </div>

        <div class="footer" v-if="sysCoupons.length == 0 && shopCoupons.length == 0 && vipCoupons.length == 0 && stageCoupons.length == 0 && synthesisCoupon.length == 0 && taskCoupons.length == 0">
          <img class="footer-img" src="../img/kong2.png" alt="">
          <p style="color: #F8DAB5;">暂无优惠券</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  props: {
    ticketBoolean: {
      type: Boolean,
      required: true
    },
    sysCoupons: {
      type: Array,
      default: () => []
    },
    totalPrice: {
      required: true,
    },
    shopCoupons: {
      type: Array,
      default: () => []
    },
    vipCoupons: {
      type: Array,
      default: () => []
    },
    stageCoupons: {
      type: Array,
      default: () => []
    },
    synthesisCoupon: {
      type: Array,
      default: () => []
    },
    taskCoupons: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    _ticketBoolean: {
      get () {
        return this.ticketBoolean
      },
      set () {
        this.$emit('closeTicket')
      }
    }
  },

  data () {
    return {
      id: '',
      isTicket: false,
      ids: [],
      name: [],
      isShare: '2', // 当前选中的店铺优惠券能否余其它券叠加,
      premiseAmount: []
    }
  },

  methods: {
    // 0 平台 1 店铺 2 vip 3 驿站 4 活动 5 任务券
    selectTicket (i, id, name, premiseAmount, isShare) {
      if (!this.isTicket) {
        if (i == 0) {
          if (this.ids[2] || this.ids[3] || this.ids[4] || this.ids[5]) {
            Toast('平台券之间不能叠加')
          } else if (this.ids[1]) {
            if (this.isShare != '1') {
              Toast('平台优惠券和当前店铺优惠券不能叠加')
            } else {
              if (this.ids[0] == id) {
                this.$set(this.ids, 0, '')
                this.$set(this.name, 0, '')
                this.$set(this.premiseAmount, 0, '')
              } else {
                this.$set(this.ids, 0, id)
                this.$set(this.name, 0, name)
                this.$set(this.premiseAmount, 0, premiseAmount)
              }
            }
          } else {
            if (this.ids[0] == id) {
              this.$set(this.ids, 0, '')
              this.$set(this.name, 0, '')
              this.$set(this.premiseAmount, 0, '')
            } else {
              this.$set(this.ids, 0, id)
              this.$set(this.name, 0, name)
              this.$set(this.premiseAmount, 0, premiseAmount)
            }
          }
        } else if (i == 1) {
          if (isShare == '1') {
            this.isShare = '1'
            if (this.ids[1] == id) {
              this.$set(this.ids, 1, '')
              this.$set(this.name, 1, '')
              this.$set(this.premiseAmount, 1, '')
            } else {
              this.$set(this.ids, 1, id)
              this.$set(this.name, 1, name)
              this.$set(this.premiseAmount, 1, premiseAmount)
            }
          } else {
            this.isShare = '0'
            if (this.ids[0] || this.ids[2] || this.ids[3] || this.ids[4] || this.ids[5]) {
              Toast('所选店铺优惠券和其它优惠券不能叠加')
            } else {
              if (this.ids[1] == id) {
                this.$set(this.ids, 1, '')
                this.$set(this.name, 1, '')
                this.$set(this.premiseAmount, 1, '')
                
              } else {
                this.$set(this.ids, 1, id)
                this.$set(this.name, 1, name)
                this.$set(this.premiseAmount, 1, premiseAmount)
              }
            }
          }
        } else if (i == 2) {
          if (this.ids[0] || this.ids[3] || this.ids[4] || this.ids[5]) {
            Toast('平台券之间不能叠加')
          } else if (this.ids[1]) {
            if (this.isShare != '1') {
              Toast('VIP优惠券券和当前店铺优惠券不能叠加')
            } else {
              if (this.ids[2] == id) {
                this.$set(this.ids, 2, '')
                this.$set(this.name, 2, '')
                this.$set(this.premiseAmount, 2, '')
              } else {
                this.$set(this.ids, 2, id)
                this.$set(this.name, 2, name)
                this.$set(this.premiseAmount, 2, premiseAmount)
              }
            }
          } else {
            if (this.ids[2] == id) {
              this.$set(this.ids, 2, '')
              this.$set(this.name, 2, '')
              this.$set(this.premiseAmount, 2, '')
            } else {
              this.$set(this.ids, 2, id)
              this.$set(this.name, 2, name)
              this.$set(this.premiseAmount, 2, premiseAmount)
            }
          }
        } else if (i == 3) {
          if (this.ids[0] || this.ids[2] || this.ids[4] || this.ids[5]) {
            Toast('平台券之间不能叠加')
          } else if (this.ids[1]) {
            if (this.isShare != '1') {
              Toast('驿站券和当前店铺优惠券不能叠加')
            } else {
              if (this.ids[3] == id) {
                this.$set(this.ids, 3, '')
                this.$set(this.name, 3, '')
                this.$set(this.premiseAmount, 3, '')
              } else {
                this.$set(this.ids, 3, id)
                this.$set(this.name, 3, name)
                this.$set(this.premiseAmount, 3, premiseAmount)
              }
            }
          } else {
            if (this.ids[3] == id) {
              this.$set(this.ids, 3, '')
              this.$set(this.name, 3, '')
              this.$set(this.premiseAmount, 3, '')
            } else {
              this.$set(this.ids, 3, id)
              this.$set(this.name, 3, name)
              this.$set(this.premiseAmount, 3, premiseAmount)
            }
          }
        } else if (i == 4) {
          if (this.ids[0] || this.ids[2] || this.ids[3] || this.ids[5]) {
            Toast('平台券之间不能叠加')
          } else if (this.ids[1]) {
            if (this.isShare != '1') {
              Toast('活动券和当前店铺优惠券不能叠加')
            } else {
              if (this.ids[4] == id) {
                this.$set(this.ids, 4, '')
                this.$set(this.name, 4, '')
                this.$set(this.premiseAmount, 4, '')
              } else {
                this.$set(this.ids, 4, id)
                this.$set(this.name, 4, name)
                this.$set(this.premiseAmount, 4, premiseAmount)
              }
            }
          } else {
            if (this.ids[4] == id) {
              this.$set(this.ids, 4, '')
              this.$set(this.name, 4, '')
              this.$set(this.premiseAmount, 4, '')
            } else {
              this.$set(this.ids, 4, id)
              this.$set(this.name, 4, name)
              this.$set(this.premiseAmount, 4, premiseAmount)
            }
          }
        } else if (i == 5) {
          if (this.ids[0] || this.ids[2] || this.ids[3] || this.ids[4]) {
            Toast('平台券之间不能叠加')
          } else if (this.ids[1]) {
            if (this.isShare != '1') {
              Toast('任务券和当前店铺优惠券不能叠加')
            } else {
              if (this.ids[5] == id) {
                this.$set(this.ids, 5, '')
                this.$set(this.name, 5, '')
                this.$set(this.premiseAmount, 5, '')
              } else {
                this.$set(this.ids, 5, id)
                this.$set(this.name, 5, name)
                this.$set(this.premiseAmount, 5, premiseAmount)
              }
            }
          } else {
            if (this.ids[5] == id) {
              this.$set(this.ids, 5, '')
              this.$set(this.name, 5, '')
              this.$set(this.premiseAmount, 5, '')
            } else {
              this.$set(this.ids, 5, id)
              this.$set(this.name, 5, name)
              this.$set(this.premiseAmount, 5, premiseAmount)
            }
          }
        }
      }
    },

    change (val) {
      if (val) {
        this.isShare = '2'
        this.ids = []
        this.name = []
        this.premiseAmount = []
      }
    },

    comfirm () {
      let premiseAmount = this.premiseAmount
      let sum = 0
      premiseAmount.forEach(x => {
        if (x) {
          sum += x
        }
      })

      if (sum > this.totalPrice) {
        Toast('不满足最低消费标准')
        return
      }

      let name = JSON.parse(JSON.stringify(this.name)).filter(x => x)
      let data = {
        name,
        ticketId: {
          sysCouponId: this.ids[0] || this.ids[2] || this.ids[3] || this.ids[4] || this.ids[5] || '',
          shopCouponId: this.ids[1] || ''
        }
      }
      this.$emit('getName', data)
      this.$emit('closeTicket')
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 15px;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &-title {
    font-size: 18px;
    text-align: center;
  }
}
.tickerListBox {
  margin: 15px 0;
  position: absolute;
  top: 42px;
  bottom: 80px;
  left: 15px;
  right: 15px;
  overflow: auto;
}
.tickerListBox::-webkit-scrollbar {
  display: none;
}
.tickerList {
  border: 1px solid rgba(231,50,39,0.17);
  background: #FFF5F4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 13px 0;
  margin-bottom: 10px;
  &-left {
    width: 60px;
    padding: 0 15px;
    border-right: 1px dashed rgba(231,50,39,0.28);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-yuan {
      position: absolute;
      width: 14px;
      height: 7px;
      background: #fff;
      border: 1px solid rgba(231,50,39,0.17);
    }
    &-topYuan {
      top: -14px;
      right: -8px;
      border-radius: 0 0 7px 7px;
      border-top: 1px solid transparent;
    }
    &-botYuan {
      bottom: -14px;
      right: -8px;
      border-radius: 7px 7px 0 0;
      border-bottom: 1px solid transparent;
    }
  }
  &-right {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 10px;
    &-shuo {
      flex: 1;
      font-size: 12px;
    }
    &-img {
      width: 18px;
    }
  }
}
.huiyuan {
  border: 1px solid #EBEBEB;
}
.huiyuanTop {
  border-top: 1px solid transparent;
}
.huiyuanBot {
  border-bottom: 1px solid transparent;
}
.btn {
  &-radio {
    padding: 7px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .van-button--large {
    height: 44px;
  }
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: auto;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  &-img {
    width: 120px;
  }
}
</style>