<template>
  <div>
    <van-popup
      v-model="_paySuccessBoolean"
      closeable
      round
      position="bottom"
      :style="{ height: '35%' }">
      <div class="container">
        <p class="container-title">支付成功</p>
        <div class="container-icon">
          <div>
            <van-icon color="#F52556" size="60" name="checked" />
          </div>
          <p style="font-size: 0.5405rem;">恭喜您，支付成功！</p>
        </div>
        <div class="container-btn">
          <span>
            <van-button plain type="danger" round @click="returnHome">返回首页</van-button>
          </span>

          <span>
            <van-button type="danger" round @click="enterOrderList">去看看</van-button>
          </span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: {
    paySuccessBoolean: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    _paySuccessBoolean: {
      get () {
        return this.paySuccessBoolean
      },
      set () {
        this.$parent.paySuccessBoolean = false
      }
    }
  },

  methods: {
    enterOrderList () {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.ydEnterOrderList.postMessage('')
      }
      if (isAndroid) {
        window.android.ydEnterOrderList()
      }
    },

    returnHome () {
      this.$parent.paySuccessBoolean = false
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  display: flex;
  padding: 0.4054rem 0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  &-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-btn {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    .van-button {
      width: 3.7838rem;
      height: 1.0811rem;
    }
  }
}
</style>