<template>
  <div>
    <van-action-sheet v-model="_show">
      <div class="content">
        <div style="text-align: center;">
          <span style="font-size: 0.5405rem; color: #E54320;">￥</span>
          <span style="font-size: 0.6757rem; color: #E54320;">{{ price }}</span>
        </div>

        <div class="paymentType">
          <div class="paymentType-item border-btn" v-for="x in shopJoinUser" :key="x.id"
            @click="paymentType(x.type, x.available, x.id, x.cashWithdrawable)">
            <img style="width: 0.4865rem;" :src="x.icon" alt="">
            <span class="paymentType-item-type">{{ x.nameType }}</span>
            <span class="paymentType-item-hongmi" v-show="x.type != '6' && x.type != '7'">余额：{{ x.available + '金叶子' }}</span>
            <span>
              <van-icon v-show="x.type != type" name="checked" color="#b7b7b7" size="18" />
              <van-icon v-show="x.type == type" name="checked" color="#E54320" size="18" />
            </span>
          </div>
        </div>

        <div class="footer">
          <van-button round color="#E54320" size="large" @click="fukuan">确认付款</van-button>
        </div>

        <van-icon size="20" class="ge_van-icon" name="cross" @click="close_action_sheet" />

      </div>
    </van-action-sheet>

    <loading :loading="loading" @closeLoading="closeLoading" />
  </div>
</template>

<script>
import Loading from './Loading'
import { ydSubmit, ydZhifu, jinmiZhifu } from '@/api/flMall'
import { fuli_yundian_pay } from '@/common'
import { Toast } from 'vant'
export default {
  components: {
    Loading
  },

  data() {
    return {
      type: '-1', // 0 2 3 权益 1 4 发起 5 普通 6 微信 7 支付宝
      available: '0',
      id: '',
      loading: false
    }
  },

  props: {
    show: {
      type: Boolean,
      required: true
    },
    price: {
      required: true
    },
    data: {
      type: Array
    },
    isAdressBoolean: {
      type: Boolean,
      required: true
    },
    shopJoinUser: {
      type: Array,
      default: () => [],
    },
    ticketId: {
      type: Object,
      required: true
    }
  },

  computed: {
    _show: {
      get() {
        return this.show
      },
      set() {
        this.$parent.submitShow = false
      }
    }
  },

  methods: {
    paymentType(type, available, id, cashWithdrawable) {
      this.type = type
      this.id = id
      if (this.$route.query.affiliation == '1') {
        this.available = cashWithdrawable
      } else {
        this.available = available
      }
    },

    fukuan() {
      if (this.isAdressBoolean) {
        Toast('请选择地址')
        return
      }

      if (['0', '1', '2', '3', '4', '5'].includes(this.type)) {
        if (Number(this.price) > Number(this.available)) {
          Toast('账户余额不足')
          return
        }
      }

      this.loading = true
      const data = this.data
      const idType = {
        advertisementId: this.$route.query.advertisementId,
        advertisementType: this.$route.query.advertisementType
      }
      ydSubmit(data, this.ticketId, idType).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          this.$parent.submitShow = false
          if (['0', '1', '2', '3', '4', '5'].includes(this.type)) {
            // 金叶子支付
            if (this.$route.query.affiliation == '1') { // 金米专区支付
              this.jinmiZhifuType(res.data.data.mergeOrderId.split(','))
            } else {
              this.zhifuType(res.data.data.mergeOrderId.split(','))
            }
          } else if (this.type == '6') {
            // 微信
            this.weixinPay(res.data.data.mergeOrderId.split(','))
          } else if (this.type == '7') {
            // 支付宝
            this.zhifuType2(res.data.data.mergeOrderId.split(','))
          }
        } else {
          Toast(res.data.message)
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
        Toast('服务繁忙')
      })
    },

    zhifuType(ids) {
      this.loading = true
      let data = {
        ids,
        type: '4',
        joinId: this.id
      }
      ydZhifu(data).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          this.$emit('open_paySuccessBoolean')
        } else {
          Toast(res.data.message)
          this.loading = false
        }
      })
    },

    jinmiZhifuType(ids) {
      this.loading = true
      let data = {
        ids,
        type: '4',
        joinId: this.id
      }
      jinmiZhifu(data).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          this.$emit('open_paySuccessBoolean')
        } else {
          Toast(res.data.message)
          this.loading = false
        }
      })
    },

    weixinPay(ids) {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.wechatPay.postMessage(`ids=${JSON.stringify(ids)}&type=1&joinId=&requesetType=12&session=${this.$route.query.session}`)
      }
      if (isAndroid) {
        window.android.wxpay(`ids=${JSON.stringify(ids)}&type=1&requesetType=12&session=${this.$route.query.session}`)
      }
    },

    // 支付宝支付
    zhifuType2(ids) {
      this.loading = true
      let data = {
        ids,
        type: '2',
      }
      ydZhifu(data).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          fuli_yundian_pay(res.data, '2')
        } else {
          Toast(res.data.message)
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
        Toast('支付失败')
      })
    },

    close_action_sheet() {
      this.$parent.submitShow = false
    },

    closeLoading () {
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  padding: 1.0811rem 0.4054rem;
  min-height: 6.7568rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .paymentType {
    &-item {
      display: flex;
      align-items: center;
      padding: 0.3243rem 0;

      &-type {
        flex: 1;
        font-size: 0.3784rem;
        color: #333;
        margin-left: 0.2703rem;
      }

      &-hongmi {
        color: #E54320;
        font-size: 0.3784rem;
        margin-right: 0.2703rem;
      }
    }

    .border-btn {
      border-bottom: 1px solid #F6F6F6;
    }
  }

  .ge_van-icon {
    position: absolute;
    top: 0.4054rem;
    right: 0.4054rem;
  }
}

.footer {
  margin-top: 0.2703rem;

  .van-button--round {
    height: 40px;
  }
}
</style>