<template>
  <div style="background: #f6f6f6; height: 100%;">
    <van-nav-bar title="支付详情" left-arrow fixed placeholder @click-left="onClickLeft" />

    <div @click="$router.push({ path: '/shippingAddress', query: { first: 0 } })">
      <div v-if="address == ''" style="
          display: flex;
          padding: 0.4054rem 0.4054rem;
          align-items: center;
          background-color: #ffffff;
          border-radius: 0.1351rem;
          flex-direction: column;">
        <div>
          <van-icon size="0.5405rem" name="add-o" />
        </div>
        <div>点击添加收货地址</div>
      </div>
      <div v-if="address != ''">
        <van-cell is-link center>
          <template #title>
            <span class="title">{{ address.userName }}</span>
            <span class="title" style="margin-left: 0.1622rem">{{
              address.telNum
            }}</span>
            <van-tag color="#E54320" text-color="#ffffff" v-show="address.isDefault == 1"
              style="margin-left: 0.2162rem">默认</van-tag>
          </template>
          <template #label>
            <span class="label">{{
              address.provinceName +
              address.cityName +
              address.countyName +
              address.detailInfo
            }}</span>
          </template>
        </van-cell>
      </div>
    </div>

    <div class="shop">
      <div class="shop-item" v-for="x in result" :key="x.id">
        <div class="shop-item-shopName">
          <span>
            <van-image width="35" height="35" fit="cover" round :src="x.shopPortrait" />
          </span>
          <span style="margin-left: 0.2703rem;">{{ x.shopName }}</span>
        </div>

        <div class="shop-item-shangpin" v-for="y in x.list" :key="y.goodsSku.id">
          <span>
            <van-image width="80" height="80" fit="cover" :src="y.goodsSku.picUrl" />
          </span>

          <div class="shop-item-shangpin-right">
            <div class="shop-item-shangpin-right-container">
              <div class="shop-item-shangpin-right-container-shangpinName">{{ y.goodsSpu.name }}</div>
              <p class="shop-item-shangpin-right-container-guige" style="font-size: 12px; color: #999;">
                <span>规格：{{ y.goodsSku.name }}</span>
                <span>x{{ y.goodsSku.quantity }}</span>
              </p>
            </div>
            <div>
              <span style="font-size: 0.3784rem; color: #E54320;">￥</span>
              <span style="font-size: 0.4865rem; color: #E54320;">{{ y.goodsSku.salesPrice }}</span>
            </div>
          </div>
        </div>

        <div class="list">
          <ul>
            <li>
              <span>运费</span>
              <span>￥{{ x.postage }}</span>
            </li>
            <li>
              <span>留言</span>
              <span>
                <van-field v-model="x.userMessage" rows="1" autosize type="textarea" maxlength="20" placeholder="请输入留言" />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="mulu">
      <ul>
        <li>
          <span>商品总金额</span>
          <span>￥{{ money }}</span>
        </li>
        <li>
          <span>运费总金额</span>
          <span>￥{{ postages }}</span>
        </li>
        <li @click="openTicket" v-if="!$route.query.affiliation && !$route.query.ticketActive">
          <span>优惠券</span>
          <span>
            <span v-for="(x, i) in name" :key="i">{{ x }} <span v-if="name.length - 1 != i">,</span></span>
            <van-icon name="arrow" />
          </span>
        </li>
      </ul>
      <div class="mulu-heji">
        <span></span>
        <span>合计：￥{{ totalPrice }}</span>
      </div>
    </div>

    <div class="tijiaodingdan">
      <div class="footer">
        <span>
          <span style="font-size: 0.3784rem; color: #E54320;">￥</span>
          <span style="font-size: 0.4865rem; color: #E54320;">{{ totalPrice }}</span>
        </span>
        <span>
          <van-button round color="#E54320" size="small" @click="submit">提交订单</van-button>
        </span>
      </div>
    </div>

    <zhi-fu 
    ref="zhi_fu" :show="submitShow" :price="totalPrice" :data="data" :isAdressBoolean="isAdressBoolean"
      :shopJoinUser="shopJoinUser" :ticketId="ticketId" @open_paySuccessBoolean="open_paySuccessBoolean" />

    <pay-success :paySuccessBoolean="paySuccessBoolean" />

    <zhifu-ticket :ticketBoolean="ticketBoolean" :sysCoupons="sysCoupons" :shopCoupons="shopCoupons"
      :vipCoupons="vipCoupons" :stageCoupons="stageCoupons" :synthesisCoupon="synthesisCoupon" :taskCoupons="taskCoupons" :totalPrice="standby" @getName="getName" @closeTicket="closeTicket" />

    <ling-yuan :lingyuan="lingyuan" :data="data" :price="totalPrice" :ticketId="ticketId"
      :isAdressBoolean="isAdressBoolean" @closelingyuan="closelingyuan"
      @open_paySuccessBoolean="open_paySuccessBoolean" />

    <loading :loading="loading" @closeLoading="closeLoading" />
  </div>
</template>

<script>
import ZhiFu from './actionSheet/ZhiFu'
import PaySuccess from './actionSheet/PaySuccess'
import Loading from './actionSheet/Loading'
import ZhifuTicket from './actionSheet/ZhifuTicket'
import LingYuan from './actionSheet/LingYuan'
import { getPage } from "@/api/shoppingAddress/shoppingAddress"
import { zhifuInfo, isHonemi, useTicket } from '@/api/flMall'
import { mapGetters } from 'vuex'
import { Toast } from 'vant'
let backAddress = ''
export default {
  components: {
    ZhiFu,
    PaySuccess,
    Loading,
    ZhifuTicket,
    LingYuan
  },
  data() {
    return {
      value: '',
      submitShow: false,
      paySuccessBoolean: false,
      address: '',
      total: {},
      result: [],
      data: [],
      isAdressBoolean: false, // 判断是否选择地址
      money: 0,
      integrals: 0,
      postages: 0,
      totalPrice: 0,
      loading: false,
      shopJoinUser: [],
      ticketBoolean: false,
      sysCoupons: [],
      taskCoupons: [],
      shopCoupons: [],
      vipCoupons: [],
      stageCoupons: [],
      synthesisCoupon: [],
      name: [],
      ticketId: {
        sysCouponId: '',
        shopCouponId: ''
      },
      standby: 0,
      lingyuan: false
    }
  },

  computed: {
    ...mapGetters(['liveRecordsId']),
  },

  watch: {
    paySuccessBoolean(Boolean) {
      if (!Boolean) {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.returnHomePage.postMessage('returnHomePage')
        }
        if (isAndroid) {
          window.android.returnHomePage()
        }
      }
    },

    address: {
      handler() {
        this.zhifuInfo()
      },
      deep: true
    }
  },

  beforeRouteEnter(to, from, next) {
    if (from.name === 'ShippingAddress') {
      if (from.params.address) backAddress = from.params.address
    } else {
      backAddress = ''
    }
    next()
  },

  created() {
    if (backAddress == '' || !backAddress) {
      if (
        this.$route.query.address != null &&
        this.$route.query.address != ''
      ) {
        if (JSON.parse(this.$route.query.address) != '') {
          this.address = JSON.parse(this.$route.query.address)
        } else {
          this.getPage()
        }
      } else {
        this.getPage()
      }
    } else {
      this.address = backAddress
    }
  },

  mounted() {
    window.paySuccess = this.paySuccess
  },

  methods: {
    // 地址
    getPage() {
      getPage({ current: 1, size: 10 }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.records.length == 0) {
            this.address = ""
          } else {
            this.address = res.data.data.records[0]
          }
          this.zhifuInfo()
        }
      })
    },

    onClickLeft() {
      this.$router.go(-1)
    },

    paySuccess() {
      this.paySuccessBoolean = true
      this.showInterstitialAD()
    },

    submit() {
      if (!this.address) {
        this.isAdressBoolean = true
      } else {
        this.isAdressBoolean = false
      }
      let guige = JSON.parse(this.$route.query.guige)
      const result = JSON.parse(JSON.stringify(this.result))
      const userAddress = {
        userName: this.address.userName,
        provinceName: this.address.provinceName,
        cityName: this.address.cityName,
        countyName: this.address.countyName,
        telNum: this.address.telNum,
        detailInfo: this.address.detailInfo
      }
      guige.forEach(x => {
        x.userAddress = userAddress
        x.liveRecordsId = this.liveRecordsId
        result.forEach(y => {
          if (x.ge_id == y.id) x.userMessage = y.userMessage
        })
      })
      this.data = JSON.parse(JSON.stringify(guige))

      if (Number(this.totalPrice) > 0) {
        this.isHonemi()
      } else {
        this.lingyuan = true
      }
    },

    zhifuInfo() {
      this.loading = true
      let data = JSON.parse(this.$route.query.guige)
      if (this.address) {
        data.forEach(x => {
          x.userAddress = {
            userName: this.address.userName,
            provinceName: this.address.provinceName,
            cityName: this.address.cityName,
            countyName: this.address.countyName,
            telNum: this.address.telNum,
            detailInfo: this.address.detailInfo
          }
        })
      }
      zhifuInfo(data, this.ticketId).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          this.useTicket(res.data.data.total.standby)
          this.money = res.data.data.total.money
          this.integrals = res.data.data.total.integrals.toFixed(1)
          this.postages = res.data.data.total.postages.toFixed(2)
          this.totalPrice = res.data.data.total.total.toFixed(2)
          this.standby = res.data.data.total.standby.toFixed(2)
          let info = res.data.data.info
          const result = this.fenlei(info, 'shopId')
          this.result = result
        } else {
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
        Toast.fail('结算失败，请联系客服')
      })
    },

    // 数据分类
    fenlei(arr, group_key) {
      let map = {}
      let res = []

      for (let i = 0; i < arr.length; i++) {
        let ai = arr[i]
        if (!map[ai['goodsSku'][group_key]]) {
          map[ai.goodsSku.shopId] = [ai]
        } else {
          map[ai.goodsSku.shopId].push(ai)
        }
      }

      Object.keys(map).forEach(key => {
        let postage = 0
        map[key].forEach(x => {
          postage = ((postage * 100) + (x.postage * 100)) / 100
        })
        res.push({
          id: key,
          shopName: map[key][0].goodsSpu.shopName,
          shopPortrait: map[key][0].goodsSpu.shopPortrait,
          userMessage: '',
          integrals: map[key][0].goodsSku.integrals,
          postage: postage.toFixed(2),
          list: map[key]
        })
      })
      return res
    },

    isHonemi() {
      this.loading = true
      isHonemi().then(res => {
        if (res.data.code === 0) {
          this.loading = false
          let cloud = res.data.data.cloud
          res.data.data.shopJoinUser.forEach(x => {
            /*  if (['0', '2', '3'].includes(x.type)) {
               x.icon = require('./img/quanyi.png')
               x.nameType = '金叶子账户'//权益人
             } else if (['1', '4'].includes(x.type)) {
               x.icon = require('./img/faqi.png')
               x.nameType = '金叶子账户'//发起人
             } else if (['5'].includes(x.type)) {
               x.icon = require('./img/pu.png')
               x.nameType = '普通帐户'
             } */
            if (['0', '4'].includes(x.type)) {
              x.icon = require('./img/quanyi.png')
              x.nameType = '清澈淡远账户'//权益人
            } else if (['1'].includes(x.type)) {
              x.icon = require('./img/faqi.png')
              x.nameType = '超凡入圣账户'//发起人
            } else if (['5'].includes(x.type)) {
              x.icon = require('./img/pu.png')
              x.nameType = '普通帐户'
            } else if (['2'].includes(x.type)) {
              x.icon = require('./img/faqi.png')
              x.nameType = '扶危济困账户'//发起人
            } else if (['3'].includes(x.type)) {
              x.icon = require('./img/faqi.png')
              x.nameType = '莫问出身账户'//发起人
            }
          })
          let shopJoinUser = res.data.data.shopJoinUser
          if (cloud == '1') {
            shopJoinUser = [
              ...shopJoinUser,
              // {
              //   id: '6',
              //   type: '6',
              //   available: '6',
              //   cashWithdrawable: '6',
              //   nameType: '微信支付',
              //   icon: require('./img/weixin.png')
              // },
              {
                id: '7',
                type: '7',
                available: '7',
                cashWithdrawable: '7',
                nameType: '支付宝支付',
                icon: require('./img/zhifubao.png')
              }
            ]
          }
          // 金叶子 金米支付
          let shopJoinUserFilter = shopJoinUser.filter(x => x.type != '5' && x.type != '6' && x.type != '7')

          // 侠客集市 新人 只能现金支付
          let xiakejishi = shopJoinUser.filter(x => x.type == '6' || x.type == '7')
          if (this.$route.query.affiliation == '1') {
            this.shopJoinUser = JSON.parse(JSON.stringify(shopJoinUserFilter))
          } else {
            this.shopJoinUser = JSON.parse(JSON.stringify(xiakejishi))
          }
          if (this.shopJoinUser.length) {
            this.$refs.zhi_fu.type = this.shopJoinUser[0].type
            this.$refs.zhi_fu.id = this.shopJoinUser[0].id
            this.$refs.zhi_fu.available = this.shopJoinUser[0].available
            this.submitShow = true
          } else {
            Toast('暂无支付方式')
          }
        }
      })
    },

    open_paySuccessBoolean() {
      this.paySuccessBoolean = true
      this.showInterstitialAD()
    },

    showInterstitialAD() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.showInterstitialAD.postMessage("showInterstitialAD")
      }
      if (isAndroid) {
        window.android.showInterstitialAD()
      }
    },

    useTicket(money) {
      const data = {
        shopIds: this.$route.query.shopIds,
        spuIds: this.$route.query.id,
        money
      }
      useTicket(data).then(res => {
        if (res.data.code === 0) {
          this.sysCoupons = res.data.data.sysCoupons
          this.shopCoupons = res.data.data.shopCoupons
          this.vipCoupons = res.data.data.vipCoupons
          this.stageCoupons = res.data.data.stageCoupons
          this.synthesisCoupon = res.data.data.synthesisCoupon
          this.taskCoupons = res.data.data.taskCoupons
        }
      })
    },

    getName(data) {
      this.ticketId = data.ticketId
      this.name = data.name
      this.zhifuInfo()
    },

    openTicket() {
      this.ticketBoolean = true
    },

    closeTicket() {
      this.ticketBoolean = false
    },

    closelingyuan() {
      this.lingyuan = false
    },

    closeLoading () {
      this.loading = false
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar__left {
  padding: 0 5px;
}

.shop {
  margin-top: 0.2703rem;

  &-item {
    background: #fff;
    padding: 0 0.4054rem;
    margin-bottom: 0.2703rem;

    &-shopName {
      display: flex;
      align-items: center;
      padding: 0.2703rem 0;
    }

    &-shangpin {
      display: flex;

      &-right {
        flex: 1;
        padding: 0.0541rem 0;
        margin-left: 0.2703rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-container {
          &-shangpinName {
            font-size: 0.3784rem;
            color: #333;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }

          &-guige {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
}

.list {
  li {
    margin-bottom: 0.0811rem;
    font-size: 0.3784rem;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .van-cell {
      padding: 0;
    }

    /deep/ .van-field__control {
      text-align: right;
    }
  }
}

.mulu {
  padding: 0.2703rem 0.4054rem 0;
  background: #fff;

  li {
    margin-bottom: 0.0811rem;
    font-size: 0.3784rem;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-heji {
    display: flex;
    justify-content: space-between;
    font-size: 0.3784rem;
    color: #333;
    padding: 0.2703rem 0;
    border-top: 1px solid #EFEFEF;
    margin-top: 0.4054rem;
  }
}

.margin-bottom {
  margin-bottom: 0 !important;
}

.tijiaodingdan {
  padding: 0.8919rem 0;
}

.footer {
  width: 100%;
  background: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2703rem;
  box-sizing: border-box;

  .van-button--small {
    height: 0.973rem;
    padding: 0 0.7297rem;
  }
}
</style>